import { graphql, useStaticQuery } from 'gatsby';

const nextStepsProdQuery = () => {
  const queryResult = useStaticQuery(graphql`
    query MyQueryNextStepsProd {
      allContentfulNextStepsReport(
        filter: { environment: { eq: "production" } }
      ) {
        edges {
          node {
            id
            section {
              ... on ContentfulMainSection {
                contentful_id
                internal {
                  type
                }
                title
                text {
                  text
                }
                image {
                  contentful_id
                  file {
                    fileName
                    url
                  }
                }
                button {
                  contentful_id
                  text
                  url
                  urlInternal
                }
              }
              ... on ContentfulFormSection {
                contentful_id
                internal {
                  type
                }
                title
                description
                messageTitle
                messageDescription
                messageIcon {
                  file {
                    fileName
                    url
                  }
                }
                message
                button {
                  contentful_id
                  text
                  url
                  urlInternal
                }
              }
              ... on ContentfulSecondarySection {
                contentful_id
                internal {
                  type
                }
                title
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  return queryResult.allContentfulNextStepsReport;
};

export default nextStepsProdQuery;
