import React from 'react';
import Layout from '../components/Layout';
import NextSteps from '../sections/NextSteps';

const NextStepsPage = () => (
  <Layout logoHeader={true}>
    <NextSteps />
  </Layout>
);

export default NextStepsPage;
