import React from 'react';
import { Flex, Button, Image } from 'rebass/styled-components';
import StyledRectangle from '../components/StyledRectangle';
import ReactMarkdown from 'react-markdown';
import CardTitle from '../components/CardTitle';
import CardText from '../components/CardText';
import { Link as GatsbyLink } from 'gatsby';
import { STORAGE_KEYS } from '../resources/constants';

import circuitRight from '../../assets/images/circuit1@1.5x.svg';

const MainSection = ({ data_section, handleTrackCustomEvent }) => {
  return (
    <StyledRectangle bg="white" py={6}>
      <Flex justifyContent="space-between">
        <CardTitle color="#2f261e" mx={5}>
          {data_section.title}
        </CardTitle>
        <Image src={circuitRight} alt="circuit-right" heigth="75px" />
      </Flex>
      <CardText color="#2f261e" mx={5}>
        <ReactMarkdown source={data_section.text.text} />
      </CardText>
      <Flex justifyContent="center" alignItems="center" mt={2}>
        <GatsbyLink
          to={
            data_section.button.urlInternal
              ? data_section.button.urlInternal
              : `${data_section.button.url}?userid=${localStorage.getItem(
                  STORAGE_KEYS.ID
                )}`
          }
          target={data_section.button.urlInternal ? '' : '_blank'}
        >
          <Button
            variant="bRed"
            onClick={() => handleTrackCustomEvent(data_section.button.text)}
          >
            {data_section.button.text}
          </Button>
        </GatsbyLink>
      </Flex>
    </StyledRectangle>
  );
};

export default MainSection;
