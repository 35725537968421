import React from 'react';
import { Flex, Box, Button, Text } from 'rebass/styled-components';
import ReactMarkdown from 'react-markdown';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import StyledRectangle from './StyledRectangle';
import CardSubtitle from './CardSubtitle';
import CardText from './CardText';

const RegistrationCode = styled(Box)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 0.45px;
  text-align: center;
  color: #ff5e63;
  padding: 18px;
  border-radius: 4px;
  border: solid 3px #ff5e63;
  margin: 40px 20px;
`;

const RegistrationCodeSection = ({
  data_section,
  code,
  androidLink,
  iosLink,
  handleTrackCustomEvent
}) => {
  return (
    <>
      <Flex justifyContent="flex-start" width={1}>
        <CardSubtitle fontSize="24px" my={4}>
          {data_section.title}
        </CardSubtitle>
      </Flex>
      <StyledRectangle bg="white" py={6}>
        <Flex justifyContent="space-between">
          <CardSubtitle fontSize="18px" mx={5}>
            {data_section.subtitle}
          </CardSubtitle>
        </Flex>
        <CardText color="#2f261e" mx={5}>
          <ReactMarkdown source={data_section.text.text} />
        </CardText>
        <Text fontSize="12px" mx={5} color="#8b97a2" fontWeight="500" mt={-3}>
          {data_section.subtext}
        </Text>
        <RegistrationCode>{code}</RegistrationCode>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          {data_section.buttons.map(button => (
            <Box mb={4} key={button.contentful_id}>
              <a
                href={
                  button.text && button.text.indexOf('Apple') > 0
                    ? iosLink
                    : androidLink
                }
              >
                <Button
                  variant="bDarkRed"
                  onClick={() => handleTrackCustomEvent(button.text)}
                >
                  <Text fontSize="12px">{button.subtext}</Text>
                  <Text fontSize="16px">{button.text}</Text>
                </Button>
              </a>
            </Box>
          ))}
        </Flex>
      </StyledRectangle>
    </>
  );
};

export default RegistrationCodeSection;
