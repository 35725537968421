import React, { Fragment, useState, useEffect } from 'react';
import { Flex, Box, Image } from 'rebass/styled-components';
import { navigate } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import MainSection from '../components/MainSection';
import SecondarySection from '../components/SecondarySection';
import StayInformedSection from '../components/StayInformedSection';
import RegistrationCodeSection from '../components/RegistrationCodeSection';
import PackageCard from '../components/PackageCard';
import CardSubtitle from '../components/CardSubtitle';
import { STORAGE_KEYS } from '../resources/constants';
import circuit from '../../assets/images/circuit.svg';
import NextStepsDev from './queries/NextStepsDev';
import NextStepsProd from './queries/NextStepsProd';
import { getAccessCode } from '../services/services';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  @keyframes borderColorChange {
    0% {
      border-color: rgba(10, 153, 199, 0);
    }
    50% {
      border-color: rgba(10, 153, 199, 1);
    }
    100% {
      border-color: rgba(10, 153, 199, 0);
    }
  }
`;

const NextSteps = () => {
  const [quizIdExists, setQuizIdExists] = useState(false);
  const [accessCode, setAccessCode] = useState('NO CODE');
  const [androidLink, setAndroidLink] = useState('');
  const [iOsLink, setIosLink] = useState('');

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.QUIZ_ID)) navigate('/');
    else setQuizIdExists(true);
  }, []);

  useEffect(() => {
    getAccessCode()
      .then(response => {
        if (response.status == '200') {
          setAccessCode(response.data.accessCode);
          setAndroidLink(response.data.androidDeepLink);
          setIosLink(response.data.iosDeepLink);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const queryResult =
    process.env.ENVIRONMENT === 'development'
      ? NextStepsDev()
      : NextStepsProd();

  const data_content = queryResult.edges[0].node;

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'next steps',
      action: actionName
    });
  };

  if (!quizIdExists) return null;
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        maxWidth="550px"
        width="100%"
      >
        {data_content.section.map(section => (
          <Fragment key={section.contentful_id}>
            {section.internal.type === 'ContentfulMainSection' && (
              <Box mb={5}>
                <MainSection
                  data_section={section}
                  handleTrackCustomEvent={handleTrackCustomEvent}
                />
              </Box>
            )}

            {section.internal.type === 'ContentfulSecondarySection' && (
              <StyledBox
                style={{
                  border: '3px solid #0A99C7',
                  animation: 'borderColorChange 3s infinite'
                }}
              >
                <SecondarySection
                  data_section={section}
                  handleTrackCustomEvent={handleTrackCustomEvent()}
                />
              </StyledBox>
            )}

            {section.internal.type === 'ContentfulFormSection' && (
              <Box mt={5} px={5}>
                <StayInformedSection
                  data_section={section}
                  handleTrackCustomEvent={handleTrackCustomEvent}
                />
              </Box>
            )}
          </Fragment>
        ))}
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="flex-start" my={9}>
          <Image src={circuit} height="64px" alt="circuit" />
        </Flex>
      </Flex>
    </>
  );
};

export default NextSteps;
