import React, { Fragment } from 'react';
import { Flex, Button, Box, Text, Image } from 'rebass/styled-components';
import StyledRectangle from '../components/StyledRectangle';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { STORAGE_KEYS } from '../resources/constants';

const PackageHeader = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: 0.45px;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 20px;
`;

const PackageItem = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  padding-bottom: 11px;
  color: #161f26;
`;

const PackageSubtext = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
`;

const PackageCard = ({ data, handleTrackCustomEvent, index }) => {
  const setPackage = () => {
    localStorage.setItem(STORAGE_KEYS.PACKAGE_DATA, JSON.stringify(data));
  };

  return (
    <StyledRectangle
      bg={index % 2 != 0 ? 'white' : 'red'}
      maxWidth="170px"
      py={5}
    >
      <Flex flexDirection="column" height="80%">
        <Image
          height={60}
          src={index % 2 != 0 ? data.icon.file.url : data.iconWhite.file.url}
          alt={
            index % 2 != 0
              ? data.icon.file.fileName
              : data.iconWhite.file.fileName
          }
        ></Image>
        <PackageHeader
          sx={{
            color: index % 2 != 0 ? 'red' : 'white'
          }}
        >
          {data.title}
        </PackageHeader>
        {data.packageDetails.title && (
          <Box px={[2, 4, 5]}>
            <PackageItem>{data.packageDetails.title}</PackageItem>
          </Box>
        )}
        {data.packageDetails.items.map(element => (
          <Fragment key={element.contentful_id}>
            <Box px={[2, 4, 5]}>
              <PackageItem>• {element.item}</PackageItem>
            </Box>
          </Fragment>
        ))}
      </Flex>
      <Flex
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        height="10%"
        py={3}
      >
        {data.valuationIcon && (
          <Image
            height={26}
            src={data.valuationIcon.file.url}
            alt={data.valuationIcon.file.fileName}
          ></Image>
        )}
        <PackageSubtext
          sx={{
            color: index % 2 != 0 ? 'red' : 'white'
          }}
        >
          {data.valuation}
        </PackageSubtext>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <GatsbyLink
          to={
            data.button.urlInternal
              ? data.button.urlInternal
              : `${data.button.url}?userid=${localStorage.getItem(
                  STORAGE_KEYS.ID
                )}`
          }
          target={data.button.urlInternal ? '' : '_blank'}
        >
          <Button
            width="127px"
            height="40px"
            variant={index % 2 != 0 ? 'bRed' : 'bWhite'}
            onClick={() => {
              handleTrackCustomEvent(data.button.text);
              setPackage();
            }}
            mb={5}
          >
            {data.button.text}
          </Button>
        </GatsbyLink>
      </Flex>
    </StyledRectangle>
  );
};

export default PackageCard;
